import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment/moment';

interface ICalendarComponentProps {
  setHomeSelectedDate?: React.Dispatch<React.SetStateAction<any>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
  setDefaultRender?: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultEventRender?: React.Dispatch<React.SetStateAction<boolean>>;
  checkUpcomingDates: any[];
  passSelectedMonth: React.Dispatch<React.SetStateAction<any>>;
  eventsDate: any;
}

const monthLabel = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const CalendarComponent: React.FunctionComponent<ICalendarComponentProps> = ({
  setHomeSelectedDate,
  setSelectedDate,
  setDefaultRender,
  setDefaultEventRender,
  checkUpcomingDates,
  passSelectedMonth,
  eventsDate,
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [checker, setChecker] = useState<boolean>(false);

  const [monthYear, setMonthYear] = useState('');
  const [allDates] = useState<any>([]);

  const [year, setyear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth());
  const [day, setDay] = useState(currentDate.getDate());

  const start = moment(new Date());
  const end = moment('2023-12-31', 'YYYY-MM-DD');
  const current = start.clone();

  useEffect(() => {
    while (current.isBefore(end)) {
      allDates.push(current.format('YYYY-MM-DD'));
      current.add(1, 'day');
    }
  }, []);

  useEffect(() => {
    allDates.forEach((element: string) => {
      if (checkUpcomingDates.includes(element)) {
        setChecker(true);
      } else {
        setChecker(false);
      }
    });
  }, [checker]);

  useEffect(() => {
    const mapMonth = monthLabel[month];
    setMonthYear(mapMonth);
    if (monthYear !== '') {
      setSelectedDate(
        `${currentDate.getDate()} ${
          monthLabel[currentDate.getMonth()]
        } ${currentDate.getFullYear()}`
      );
    }
  }, [month, day, year, monthYear]);

  useEffect(() => {
    const mapMonth = monthLabel[month];
    setMonthYear(mapMonth);
    if (monthYear !== '' && setHomeSelectedDate) {
      setHomeSelectedDate(
        `${currentDate.getDate()} ${
          monthLabel[currentDate.getMonth()]
        } ${currentDate.getFullYear()}`
      );
    }
  }, [month, day, year, monthYear]);

  const handleMonth = (value: any) => {
    const tempDate = new Date(value.activeStartDate);
    setMonth(tempDate.getMonth());
    setDay(tempDate.getDate());
    setyear(tempDate.getFullYear());
    setCurrentDate(tempDate);
    if (setDefaultRender) {
      setDefaultRender(false);
    }
    if (setDefaultEventRender) {
      setDefaultEventRender(false);
    }
    passSelectedMonth(value.activeStartDate);
  };

  const mark = eventsDate.map((item: any) =>
    moment(item.date).format('DD-MM-YYYY')
  );

  return (
    <Box
      sx={{
        '.react-calendar': {
          minWidth: '240.79px',
          minHeight: '355px',
          maxWidth: { xs: '100%', sm: '340.79px' },
          maxHeight: '356.26px',
          border: '0.6px solid #ebebeb',
          backgroundColor: 'white',
          boxShadow: '18.3127px 20.7116px 83.2951px #e7e7e7',
          borderRadius: '4px',
        },
        '.highlight ': {
          backgroundColor: 'primary.500',
        },
        '.no-event-day': {
          pointerEvents: 'none',
        },
      }}
    >
      <Calendar
        minDetail="year"
        tileClassName={({ date }) => {
          if (
            mark.find(
              (foundDate: any) =>
                foundDate === moment(date).format('DD-MM-YYYY')
            )
          ) {
            return 'highlight';
          }
          return 'no-event-day';
        }}
        onActiveStartDateChange={handleMonth}
      />
    </Box>
  );
};

export default CalendarComponent;
